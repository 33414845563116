import { Link } from 'react-router-dom';

import SignUp from './../components/SignUp';
import Section from './../components/Section';
import ContentBlock from '../components/ContentBlock';

import './../css/Home.css';

export default function Home() {
  return (
    <div>
      <Section
        ariaLabel="Title Card"
        style={{
          padding: '2rem',
          backgroundColor: '#EFEFEF',
        }}
      >
        <h1>All your shows.</h1>
        <h1>All in one place.</h1>
        <a
          href="#sign-up"
          className="about-us-button"
          style={{ borderWidth: '1px', borderColor: '#00D1B8', padding: '1rem', marginTop: '1rem' }}
        >
          Sign up for free{' '}
          <span>
            <i className="bi bi-arrow-right" />
          </span>
        </a>
      </Section>
      <div style={{ borderTop: '1rem #EFEFEF solid', borderBottom: '1rem #EFEFEF solid' }}>
        <Section className="w-[75vw] p-12" align="start">
          <h1>Concert planning made simple</h1>
          <ContentBlock label="Invite friends with the click of a button">
            No more mixed messages,{' '}
            <span className="underline underline-offset-4 decoration-2 decoration-[#00D1B8]">no more confusion.</span>
          </ContentBlock>
          <ContentBlock label="Stay in the know on who's coming">
            <span className="underline underline-offset-4 decoration-2 decoration-[#00D1B8]">
              No need to chase down replies!
            </span>{' '}
            Whether it’s a sold-out stadium show or an intimate gig, you’ll always know who’s ready to rock out with
            you. Simplify your plans and focus on the music!
          </ContentBlock>
          <ContentBlock label="Stress-free booking">
            Say goodbye to the hassle of booking tickets for you and your friends.
          </ContentBlock>
        </Section>
        <Section className="w-[75vw] p-12" align="center">
          <div>
            <h1>Each ticket, tailored to you</h1>
            <p>Never forget the small details by adding customized notes to your activities.</p>
          </div>
        </Section>
      </div>
      <Section
        style={{
          backgroundColor: '#151035',
          color: '#F5F8FB',
          borderWidth: '0.5rem',
          borderColor: '#00D1B8',
          paddingBottom: '2rem',
        }}
      >
        <h1 style={{ textAlign: 'left', paddingTop: '2rem' }}>
          We're making concert tracking as <span style={{ color: '#00D1B8' }}>easy</span> as possible.
        </h1>
        <Link
          to="about"
          className="about-us-button"
          style={{ borderWidth: '1px', borderColor: '#00D1B8', padding: '1rem', marginTop: '1rem' }}
        >
          Learn more{' '}
          <span>
            <i className="bi bi-arrow-right" />
          </span>
        </Link>
      </Section>
      <Section style={{ borderWidth: '1rem', borderColor: '#EFEFEF' }} ariaLabel="Sign Up">
        <SignUp />
      </Section>
    </div>
  );
}
