import { ReactNode, CSSProperties } from 'react';

import './../css/Section.css';

interface Props {
  ariaLabel?: string;
  style?: CSSProperties;
  align?: string;
  className?: string;
  children: ReactNode;
}

export default function Section({ ariaLabel = '', style = {}, align = 'center', className = '', children }: Props) {
  return (
    <section className={`flex justify-center w-screen ${className}`} aria-label={ariaLabel} style={style}>
      <div className={`section-container flex flex-col justify-center items-${align}`}>{children}</div>
    </section>
  );
}
