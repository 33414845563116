import { ReactNode } from 'react';

interface Props {
  label: string;
  children?: ReactNode;
}

export default function ContentBlock({ label, children }: Props) {
  return (
    <div className="py-8">
      <h2 className="pb-2">{label}</h2>
      <p>{children}</p>
    </div>
  );
}
