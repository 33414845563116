import FooterElement from './FooterElement';

export default function Footer() {
  return (
    <div className="flex h-auto justify-between text-white bg-[#1C2022] px-8 pb-10">
      <div>
        <p
          className="uppercase font-bold p-2"
          style={{ fontVariant: 'all-small-caps', fontWeight: 'bold', color: '#F5F8FB' }}
        >
          Showboat © 2025
        </p>
      </div>

      <div className="flex justify-around">
        <div className="flex flex-col">
          <p className="capitalize font-bold p-2">Company</p>
          <FooterElement label="About Us" link="about" />
          <FooterElement label="Contact Us" link="" />
        </div>
        <div className="flex flex-col">
          <p className="capitalize font-bold p-2">Socials</p>
          <FooterElement label="Instagram" link="" icon="bi bi-instagram" />
          <FooterElement label="Twitter" link="" icon="bi bi-twitter-x" />
        </div>
      </div>
    </div>
  );
}
